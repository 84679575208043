import './Difficulty.css'
import star from '../../assets/star.png';
import halfStar from '../../assets/star-half.png';
import { useEffect, useState } from 'react';

function Difficulty(props: any) {
    
    const {
        difficulte
    } = props;

    useEffect(() => {
        const starContainer: HTMLElement = document.getElementById('star-container')!;

        starContainer.innerHTML = '';
        console.log(difficulte/200, difficulte)

        for(let i = 0; i < Math.floor(difficulte/200); i++) {
            const image = document.createElement('img');
            image.setAttribute('src', star);
            image.classList.add('star');
            starContainer.appendChild(image);
        }

        if ((difficulte/200 - Math.floor(difficulte/200) !== 0)) {
            const image = document.createElement('img');
            image.setAttribute('src', halfStar);
            image.classList.add('star');
            starContainer.appendChild(image);
        }
    }, [difficulte]);

    return (
    <div id="star-container"></div>
    );
}

export default Difficulty;

import './App.css'
import logo from '../../assets/logo.png';
import { useEffect, useState } from 'react';
import ShowReponse from '../ShowReponse/ShowReponse';
import PlayAgain from '../PlayAgain/PlayAgain';
import Difficulty from '../Difficulty/Difficulty';

function App() {

  const [question, updateQuestion] = useState(null as any);

  async function getTraduction(trad: string) {
    return fetch(`https://api-free.deepl.com/v2/translate?source_lang=EN&target_lang=FR&text=${trad}&auth_key=826be741-81c8-f0b7-41f1-6f7d4c865b60:fx`)
      .then((response) => response.json())
      .then((data) => {
        return data.translations[0].text;
      });
  }

  useEffect(() => {
    fetch("https://jservice.io/api/random")
        .then((response) => response.json())
        .then(async (data) => {
          const newQuestion = {
            question: await getTraduction(data[0].question),
            reponse: await getTraduction(data[0].answer),
            difficulte: data[0].value,
          }
          updateQuestion(newQuestion);
        });
  }, []);

  return (
    <div className="container flex flex-col items-center min-h-[100vh] min-w-[100vw] text-white bg-center">
      <h1 className='w-[400px] m-[60px] flex justify-center'><img className="max-w-[300px]" src={logo} alt="Jeopardy"/></h1>
      <h2 className="m-[15px] text-[20px] font-semibold">Quel est la réponse ?</h2>
      <p className='subtitle'>Difficulté:</p>
      <Difficulty difficulte={question?.difficulte}/>
      <p className='subtitle'>Indice:</p>
      {question && <p className='max-w-[80%] text-center'>{question.question}</p>}
      <p className='subtitle'>Réponse:</p>
      {question && <ShowReponse reponse={question.reponse}/>}
      <PlayAgain/>
    </div>
  );
}

export default App;

import './ShowReponse.css'
import { useEffect, useState } from 'react';

function ShowReponse(props: any) {
    
    const {
        reponse
    } = props;

    const [showReponse, updateShowReponse] = useState(false);

    function handleShow() {
        updateShowReponse(!showReponse);
    }

    return (
        <div className="cursor-pointer" onClick={handleShow}>
            {!showReponse && <div className='bg-white px-[25px] py-[15px] rounded-xl text-purple-700 font-bold text-[20px] border-[5px] border-solid border-purple-700'>
                <p>Voir la réponse ?</p>
            </div>}
            {showReponse && <p>{reponse}</p>}
        </div>
    );
}

export default ShowReponse;

import './PlayAgain.css'
import { useEffect, useState } from 'react';

function PlayAgain() {

    function handlePlayAgain() {
        window.location.reload();
    }

    return (
        <div onClick={handlePlayAgain} className='playagain transition-all duration-300 ease-in-out mt-[20px] cursor-pointer bg-white px-[25px] py-[15px] rounded-xl text-purple-700 font-bold text-[20px] border-[5px] border-solid border-purple-700'>
            <p>Rejouer ?</p>
        </div>
    );
}

export default PlayAgain;
